














































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import {
  InputFieldCpt,
  InputNumberCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  ValidationSummaryCpt,
  ValidationHintCpt,
  SpinnerCpt,
  BaseFormComponent,
} from '@/core/components';
import { ApiService, AxiosFactory, DownloadProvider } from '@/core/services';
import { PlayerImportLookupModel, PlayerImportModel } from '@/core/webapi';
import { GameFormats } from '@/core/constants';

@Component({
  components: {
    InputFieldCpt,
    InputNumberCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class PlayerImportView extends BaseFormComponent {
  players = [] as PlayerImportModel[];
  lookups = {} as PlayerImportLookupModel;
  loading = true;

  get playingPositions15s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Fifteens);
    });
  }

  get playingPositions7s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Sevens);
    });
  }

  async created() {
    try {
      this.lookups = (await ApiService.users().getManageUserFormLookupData()).data;
    } finally {
      this.loading = false;
    }
  }

  async downloadPlayerBulkUploadSampleXlsx() {
    const response = (await ApiService.resources().getDocument('playerbulkimportxls')).data;
    DownloadProvider.downloadResponse(response);
  }

  async onFileSelected(file: any) {
    this.loading = true;

    try {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          this.players = await this.uploadAndParse(e.target.result);

          if (this.players.length === 0) {
            this.$notify({
              title: this.$t('Warning') as string,
              message: this.$t('No players parsed from the file') as string,
              type: 'warning',
            });
          }
        } finally {
          this.clearFiles();
          this.loading = false;
        }
      };

      reader.readAsDataURL(file.file);
    } finally {
      this.clearFiles();
      this.loading = false;
    }
  }

  async uploadAndParse(f: any) {
    const file = new FormData();
    file.append('file', f);

    const response = await AxiosFactory.create().post('/api/v1/players/uploadAndParseXls', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return this.fixNumericProps(response.data);
  }

  async onSubmitForm() {
    const toImportAmount = this.players?.filter(p => p.shouldImport).length ?? 0;
    if (!toImportAmount) {
      this.$notify({
        title: this.$t('Nothing to import') as string,
        message: this.$t('No players selected to import') as string,
        type: 'warning',
      });
      return;
    }

    this.loading = true;

    try {
      const response = await ApiService.players().importPlayers(this.players);
      const importedAmount = response.data?.filter(p => p.isImportSuccessful).length ?? 0;

      if (!!importedAmount) {
        this.$notify({
          title: this.$t('Success') as string,
          message: this.$t(`${importedAmount} players imported`) as string,
          type: 'success',
        });

        this.$router.push('/players');
      } else {
        if (response.data) {
          this.players = this.fixNumericProps(response.data);
        }

        this.$notify({
          title: this.$t('Validation failed') as string,
          message: this.$t('Please check validation messages') as string,
          type: 'error',
        });
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  cancelAndReUpload() {
    this.players = [];
    this.clearFiles();
  }

  clearFiles() {
    const uploadRef = this.$refs.upload as any;
    if (uploadRef) {
      uploadRef.clearFiles();
    }
  }

  disableImport(modelState: any) {
    // If no modelState errors, or if all of them have been addressed
    return !!Object.keys(modelState).filter(p => !!modelState[p]).length;
  }

  fixNumericProps(players: PlayerImportModel[]) {
    // SEE: https://stackoverflow.com/a/62635389/413785
    // Not sanitizing this would cause null (which is returned from the API) to be converted to 0 inside
    // the input-number component, and WRY wanted the field to remain empty, and not to default to 0
    return players?.map(p => {
      return {
        ...p,
        height: p.height ?? undefined,
        weight: p.weight ?? undefined,
      };
    });
  }

  onPositionChanged(player: PlayerImportModel) {
    if (player?.modelState) {
      player.modelState.usual7sPositionId = null as any;
      player.modelState.usual15sPositionId = null as any;
    }
  }
}
