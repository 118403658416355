






























































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import ExistingDraftsWarningCpt from '@/modules/reports/components/ExistingDraftsWarningCpt.vue';
import { InputFieldCpt, PlayerFinderDialogCpt } from '@/core/components';
import { PlayerCollectionModel, PlayerGridFilter, PlayerGridLookupModel, SquadModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
} from '@/core/components/grid';
import { PlayerDeduplicateCpt } from '@/modules/admin/users/components';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    ExistingDraftsWarningCpt,
    InputFieldCpt,
    PlayerFinderDialogCpt,
    PlayerDeduplicateCpt,
  },
})
export default class PlayersGridView extends AppVue {
  grid = {} as GridModel<PlayerCollectionModel>;
  internalCollection = [] as PlayerCollectionModel[];
  lookups = {} as PlayerGridLookupModel;
  squadModel = new SquadModel({ modelState: {} } as any);
  showSquadDialog = false;
  showDeduplicateDialog = false;
  deduplicateKeepUserId = 0;

  get isAnySquadSelected() {
    return this.grid?.filter?.group && !isNaN(+this.grid?.filter.group);
  }

  async created() {
    await this.initializeLookups();

    this.$eventHub.$on('GRID_DATA_UPDATED', () => this.initializeInternalCollection());

    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.players().filterPlayers(filter as PlayerGridFilter),
      routeName: 'players',
      defaultFilterOverrides: new GridFilter({
        group: this.$route.params.group || ((await this.anyFavourites()) ? 'favourites' : 'all'),
      }),
    });
  }

  async anyFavourites() {
    return (await ApiService.players().anyFavourites()).data;
  }

  async initializeInternalCollection() {
    this.internalCollection = _.cloneDeep(this.grid?.collection?.items) || [];
  }

  async initializeLookups() {
    this.lookups = (await ApiService.players().getPlayerGridLookupData()).data;
  }

  async flipFavourite(player: PlayerCollectionModel) {
    player.isFavourite
      ? await ApiService.players().removeFavourite(player.id)
      : await ApiService.players().addFavourite(player.id);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t(player.isFavourite ? 'Removed from favourites' : 'Added to favourites') as string,
      type: 'success',
    });

    this.grid.update();
  }

  async flipRetired(player: PlayerCollectionModel) {
    player.isRetired ? await ApiService.players().unretire(player.id) : await ApiService.players().retire(player.id);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t(player.isRetired ? 'Player unretired' : 'Player retired') as string,
      type: 'success',
    });

    this.grid.update();
  }

  addSquad() {
    this.showSquadDialog = true;
    this.squadModel.id = 0;
    this.squadModel.name = '';
  }

  async renameSquad() {
    this.showSquadDialog = true;
    this.squadModel.id = +this.grid.filter.group;
    this.squadModel.name = this.lookups?.squads?.find(s => s.id === this.squadModel.id)?.name ?? '';
  }

  async removeSquad() {
    if (!confirm(this.$t("Are you sure you want to delete this squad (player data won't be deleted)?") as string)) {
      return;
    }

    await ApiService.squads().deleteSquad(+this.grid.filter.group);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Squad deleted') as string,
      type: 'success',
    });

    this.grid.filter.group = 'all';
    this.grid.update();
    await this.initializeLookups();
  }

  async onSquadDialogSaveItem() {
    await this.saveSquad().then(async () => {
      this.onSquadDialogClose();
      await this.initializeLookups();
    });
  }

  private saveSquad(): any {
    return this.squadModel.id
      ? ApiService.squads().updateSquad(this.squadModel).handleValidationErrors(this.squadModel)
      : ApiService.squads().createSquad(this.squadModel).handleValidationErrors(this.squadModel);
  }

  onSquadDialogClose() {
    this.showSquadDialog = false;
    this.squadModel = new SquadModel({ modelState: {} } as any);
  }

  async updateUserSquads(player: PlayerCollectionModel, squadIds: number[]) {
    const previousSquadIds = this.internalCollection?.find(p => p.id === player.id)?.squadIds ?? [];

    if (squadIds.length > previousSquadIds.length) {
      const newSquadId = squadIds.find(s => !previousSquadIds.includes(s));
      if (newSquadId) {
        await ApiService.squads().addPlayerToSquad(newSquadId, player.id);
        this.$notify({
          title: this.$t('Success') as string,
          message: this.$t('Player added to squad') as string,
          type: 'success',
        });
      }
    } else if (squadIds.length < previousSquadIds.length) {
      const removedSquadId = previousSquadIds.find(s => !squadIds.includes(s));
      if (removedSquadId) {
        await ApiService.squads().removePlayerFromSquad(removedSquadId, player.id);
        this.$notify({
          title: this.$t('Success') as string,
          message: this.$t('Player removed from squad') as string,
          type: 'success',
        });
      }
    }

    this.grid.update();
  }

  deduplicate(player: PlayerCollectionModel) {
    this.showDeduplicateDialog = true;
    this.deduplicateKeepUserId = player.id;
  }

  onDeduplicateClose(refreshData: boolean) {
    this.showDeduplicateDialog = false;
    this.deduplicateKeepUserId = 0;

    if (refreshData) {
      this.grid.update();
    }
  }
}
