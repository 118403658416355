






































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { UserAccountLookupModel, UserAccountModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import {
  DetailHeaderCpt,
  DetailsTableRowCpt,
  DetailsDateTableRowCpt,
  DetailsLookupTableRowCpt,
  SpinnerCpt,
} from '@/core/components';
import { GameFormats } from '@/core/constants';

@Component({
  components: {
    DetailHeaderCpt,
    DetailsTableRowCpt,
    DetailsDateTableRowCpt,
    DetailsLookupTableRowCpt,
    SpinnerCpt,
  },
})
export default class PlayerDetailsView extends AppVue {
  model = {} as UserAccountModel;
  lookups = {} as UserAccountLookupModel;
  loading = true;

  get playerId() {
    return parseInt(this.$route.params.playerId, 10);
  }

  get title() {
    return `${this.model?.firstName ?? ''} ${this.model?.lastName ?? ''}`.trim() ?? null;
  }

  get playingPositions15s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Fifteens);
    });
  }

  get playingPositions7s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Sevens);
    });
  }

  async created() {
    try {
      this.lookups = (await ApiService.users().getManageUserFormLookupData()).data;
      this.model = (await ApiService.users().getAccount(this.playerId)).data;
    } finally {
      this.loading = false;
    }
  }
}
